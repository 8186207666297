import React, { useEffect, useState } from "react";
import { Model, StylesManager } from "survey-core";
import { Survey } from "survey-react-ui";
import "survey-core/defaultV2.css";
import axios from 'axios';

import { json } from "../data/survey_json.js";

StylesManager.applyTheme("defaultV2");

// Get the current URL
const currentUrl = window.location.href;
// Create a URL object
const url = new URL(currentUrl);
// Use URLSearchParams to extract query parameters
const params = new URLSearchParams(url.search);
// Get individual parameters
const resp_id = params.get('resp_id'); // resp_id
const sid = params.get('sid');
const jumpOn = params.get('j');



console.log("sid=",sid)

const postData = async (options) => {
  try {

    const response = await axios.post(`https://profilingbackend.rapidsay.com/api/profiling/${sid}/${resp_id}`, options, { 
      headers: {
        'Content-Type': 'application/json',
        // Add any additional headers if required
      },
      body: JSON.stringify(options),
    });

    // Process the response data, if needed
   // console.log(response.data); // Log the response data to the console

  } catch (error) {
    console.error('Error:', error);
    // Handle errors, if any
  }
};

function onComplete(survey) {
 // console.log("Survey complete! Results: " + JSON.stringify(survey.data));
}

const convertDataToSurveyJSON = (data) => {
  return {
      pages: data.map(item => { 
        
        let newElement=undefined;
          if(item.type=="matrix")
          {
             newElement=item;
          }

          const element = {
              name: item.name,
              title: item.label_jp,
              type: item.type,
              choices: item.choices_jp ? item.choices_jp.map(choice => {
                  return {
                      value: choice.choice_number,
                      text: choice.label
                  };
              }) : [],
              visibleIf: item.visibleIf,
              isRequired: true
          };

          // Keys to check for inclusion
          const keysToInclude = ['inputType', 'autocomplete', 'elements'];

          // Iterate over keys and include them if present
          keysToInclude.forEach(key => {
              if (item[key]) {
                  element[key] = item[key];
              }
          });
          

          return {
              elements: [newElement??element]
          };
      })
  };
};


export function SurveyPage() {
  const [profilingData, setProfilingData] = useState([]);
  const [model, setModel] = useState(null); // Initialize model as null
  const [storedOptions, setStoredOptions] = useState(null);
  const [q061, setq061] = useState([]);

  function onValueChanged(_, options) {
   // console.log("New value: " + JSON.stringify(options));
    
    //custom logic for q061
    const newArray = [...q061];
    if(options.name=="birthYear")
    {
      newArray[0] = options.value;
      setq061(newArray);
      options={};
      options.name="q061";
      options.value=newArray.join("-");
    }

    if(options.name=="birthMonth")
    {
      newArray[1] = options.value;
      setq061(newArray);
      options={};
      options.name="q061";
      options.value=newArray.join("-");
    }
    //custom logic ends here

    
   // console.log("options=",options)
    setStoredOptions(options);
  }
  
  function onCurrentPageChanged(_, options) {
   // console.log("Stored options: " + JSON.stringify(storedOptions));
    postData(storedOptions)
  }

  useEffect(() => {

    const fetchProfilingData = async () => {
      try {
        const response = await axios.get(`https://profilingbackend.rapidsay.com/api/profiling/${sid}/${resp_id}`);
        setProfilingData(response.data);
        // console.log("profilingData=",response.data)
      } catch (error) {
        console.error('Error fetching profiling data:', error);
      }
    };

    fetchProfilingData();
  }, []);

  // Update the survey model with fetched data
  useEffect(() => {
    if (profilingData.length > 0) {
      const surveyJSON = convertDataToSurveyJSON(profilingData);
      surveyJSON.showQuestionNumbers="off";
      surveyJSON.showPrevButton="off";
      surveyJSON.completedHtml="<h4>Thank you for completing the survey!</h4><p>We will get back to you soon.</p>";
      // console.log("surveyJSON=",JSON.stringify(surveyJSON))
      const newModel = new Model(surveyJSON); // Create a new model with fetched data
      setModel(newModel);

      // Attach a callback to the onComplete event
      newModel.onComplete.add(function(result) {
        // Redirect to a new URL
       // console.log("survey is completedddd");
        // window.location.href = "https://www.google.com";
        window.location.href = `https://api.rapidsay.com/api/profiling/${resp_id}/${sid}/1`;
      });

      // if(jumpOn!=="")
      // {
      //   console.log("jump on=",atob(jumpOn))
      //   jumpToQuestion("q002")
      // }
        
    }
  }, [profilingData]); // Update model when profilingData changes

  

  const jumpToQuestion = (questionName) => {
    if (model) {
      const question = model.getQuestionByName(questionName);
      if (question) {
        model.currentPage = question.page;
        question.focus();
      } else {
        console.warn(`Question with name "${questionName}" not found.`);
      }
    }
  };


  // Render Survey component only when model is not null
  return (
    <div className="container">
      <h1></h1>
      {model && (
        <Survey
          model={model}
          onComplete={onComplete}
          onValueChanged={onValueChanged}
          onCurrentPageChanged={onCurrentPageChanged}
        />
      )}
      {/* <button onClick={() => jumpToQuestion("q002")}>Jump to Question 2</button> */}
    </div>
  );
}
